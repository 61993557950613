<template>
    <div class="proCom3">
        <div class="pcNum">02</div>
        <!-- 左边文字小图片 -->
        <div class="pcTLeftText">
            <!-- 文字 -->
            <div class="pcTTop">
                <div class="pcTIcon">
                    <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                </div>
                <div class="pcTBig">产品品牌型</div>
                <div class="pcTSmall">
                    对于产品品牌众多的企业，可以单独建立各个品牌的独立网站，以便市场
                    营销策略与网站宣传统一。此类网站运用场景围绕着产品、产品使用用户互动、 促销优惠以及吸引粉丝等活动。除了构建产品网站本身功能外，
                    建议增强再增加互动游戏、网络推广等配套措施，增加产品品牌网站的使用效果。
                </div>
            </div>
            <!-- 小图片 -->
            <div class="pcTBottom">
                <ul>
                    <li class="pcTLiImg" @mousemove="showRight(1)">
                        <img class="pcTImg" src="http://iv.vu818.com/img/pc131.jpg" alt="" />
                    </li>
                    <li class="pcTLiImg" @mousemove="showRight(2)">
                        <img class="pcTImg" src="http://iv.vu818.com/img/pc132.jpg" alt="" />
                    </li>
                    <li class="pcTLiImg" @mousemove="showRight(3)">
                        <img class="pcTImg" src="http://iv.vu818.com/img/pc133.jpg" alt="" />
                    </li>
                    <li class="pcTLiImg" @mousemove="showRight(4)">
                        <img class="pcTImg" src="http://iv.vu818.com/img/pc134.jpg" alt="" />
                    </li>
                </ul>
            </div>
        </div>
        <!-- 右边大图片 -->
        <div class="pcTRightImg">
            <img class="pcTBigImg" src="http://iv.vu818.com/img/pcdn.png" alt="" />
            <ul>
                <li class="pcRLiImg1" v-show="showRImg === 1">
                    <img class="pcRImg" src="http://iv.vu818.com/img/pc131.jpg" alt="" />
                </li>
                <li class="pcRLiImg2" v-show="showRImg === 2">
                    <img class="pcRImg" src="http://iv.vu818.com/img/pc132.jpg" alt="" />
                </li>
                <li class="pcRLiImg3" v-show="showRImg === 3">
                    <img class="pcRImg" src="http://iv.vu818.com/img/pc133.jpg" alt="" />
                </li>
                <li class="pcRLiImg4" v-show="showRImg === 4">
                    <img class="pcRImg" src="http://iv.vu818.com/img/pc134.jpg" alt="" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProCom3',
    data() {
        return {
            showRImg: 1,
        };
    },
    methods: {
        showRight(i) {
            this.showRImg = i;
        },
    },
};
</script>

<style lang="less" scoped>
.proCom3 {
    max-width: 1920px;
    height: 700px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    .pcNum {
        position: absolute;
        right: -22px;
        top: -90px;
        font-family: 'DIN-Medium';
        font-size: 400px;
        color: #ff6c00;
        opacity: 0.05;
    }
    // 左边文字小图片
    .pcTLeftText {
        width: 555px;
        height: 100%;
        float: left;
        margin-left: 80px;
        // 文字
        .pcTTop {
            width: 100%;
            height: 300px;
            margin-top: 100px;
            .pcTIcon {
                width: 55px;
                height: 75px;
                overflow: hidden;
                position: relative;
                img {
                    position: absolute;
                    top: -130px;
                    left: -60px;
                }
            }
            .pcTBig {
                width: 100%;
                height: 100px;
                line-height: 100px;
                font-size: 40px;
                font-weight: 900;
            }
            .pcTSmall {
                width: 100%;
                height: 100px;
                margin-top: 30px;
                line-height: 30px;
                text-align: left;
            }
        }
        // 图片
        .pcTBottom {
            width: 100%;
            height: 68px;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .pcTLiImg {
                    width: 22%;
                    height: 100%;
                    overflow: hidden;
                    border-radius: 20px;
                    .pcTImg {
                        width: 100%;
                        height: 100%;

                        transition: 0.5s;
                    }
                }
                .pcTLiImg:hover {
                    box-shadow: 1px 1px 10px 1px gray;
                    .pcTImg {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    // 右边大图片
    .pcTRightImg {
        width: 606px;
        height: 500px;
        margin-top: 100px;
        float: left;
        position: relative;
        overflow: hidden;
        margin-left: 50px;
        .pcTBigImg {
            width: 606px;
            height: 500px;
        }
        ul {
            width: 100%;
            .pcRLiImg1,
            .pcRLiImg2,
            .pcRLiImg3,
            .pcRLiImg4 {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 13px;
                left: 0px;
                .pcRImg {
                    width: 582px;
                    height: 357px;
                    border-radius: 5px;
                }
            }
        }
    }
}
</style>

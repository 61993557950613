<template>
    <div class="proCom4">
        <div class="pcNum">03</div>
        <!-- 左边图片 -->
        <div class="pc4Left">
            <img
                src="http://iv.vu818.com/img/pc141.png"
                class="pc4Img1 wow animate__animated animate__slideInLeft"
                data-wow-delay="0.1s"
            />
            <img src="http://iv.vu818.com/img/pc142.png" class="pc4Img2 wow animate__animated animate__slideInDown" />
            <img
                src="http://iv.vu818.com/img/pc143.png"
                class="pc4Img3 wow animate__animated animate__slideInRight"
                data-wow-delay="0.2s"
            />
            <img
                src="http://iv.vu818.com/img/pc144.png"
                class="pc4Img4 wow animate__animated animate__slideInUp"
                data-wow-delay="0.3s"
            />
        </div>
        <!-- 右图文 -->
        <div class="pc4Right">
            <!-- 文字 -->
            <div class="pcrTop">
                <div class="pcfIcon">
                    <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                </div>
                <div class="pcrBig">品牌活动互动型</div>
                <div class="pcrSmall">
                    对于新产品、应季产品或市场策略的促销活动，
                    活动互动型网站是最好的选择。活动互动型，是在产品品牌型网站基础上增加移动
                    互联网的综合运用，如微信公众号、小程序、扫 一扫、摇一摇、互动游戏、AI小程序等综合运用。
                </div>
            </div>
            <!-- 图片 -->
            <div class="pcrImgs">
                <ul>
                    <li class="pcrILi">
                        <img src="http://iv.vu818.com/img/pc145.jpg" alt="" />
                    </li>
                    <li class="pcrILi">
                        <img src="http://iv.vu818.com/img/pc146.jpg" alt="" />
                    </li>
                    <li class="pcrILi">
                        <img src="http://iv.vu818.com/img/pc147.jpg" alt="" />
                    </li>
                    <li class="pcrILi">
                        <img src="http://iv.vu818.com/img/pc148.jpg" alt="" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProCom4",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.proCom4 {
    max-width: 1920px;
    height: 700px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    .pcNum {
        position: absolute;
        right: -22px;
        top: -90px;
        font-family: "DIN-Medium";
        font-size: 400px;
        color: #ff6c00;
        opacity: 0.05;
    }
    // 左边图片
    .pc4Left {
        width: 50%;
        height: 100%;
        position: relative;
        float: left;

        .pc4Img1,
        .pc4Img2,
        .pc4Img3,
        .pc4Img4 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
    // 右图文
    .pc4Right {
        width: 40%;
        height: 100%;
        float: right;
        margin-right: 100px;
        // 文字
        .pcrTop {
            width: 100%;
            height: 300px;
            margin-top: 100px;
            .pcfIcon {
                width: 60px;
                height: 75px;
                overflow: hidden;
                position: relative;
                img {
                    position: absolute;
                    top: -135px;
                    left: 3px;
                }
            }
            .pcrBig {
                width: 100%;
                height: 100px;
                line-height: 100px;
                font-size: 40px;
                font-weight: 900;
            }
            .pcrSmall {
                width: 100%;
                height: 100px;
                margin-top: 30px;
                line-height: 30px;
                text-align: left;
            }
        }
        // 图片
        .pcrImgs {
            width: 100%;
            height: 192px;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .pcrILi {
                    transition: 0.5s;
                    border-radius: 10px;
                    overflow: hidden;
                }
                .pcrILi:hover {
                    transform: translateY(-10px);
                }
            }
        }
    }
}
</style>
